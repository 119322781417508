
import { IDocumentFile, DocumentPreviewType } from '@aston/foundation';
import { AppConstants } from 'apps/debtor-portal/src/app/app.constants';

export function previewType(document: IDocumentFile) {
	if (AppConstants.IMAGE_PREVIEW_MATCH.test(document.mimeType)) {
		return DocumentPreviewType.Image;
	}
	if (AppConstants.IFRAME_PREVIEW_MATCH.test(document.mimeType)) {
		return DocumentPreviewType.Iframe;
	}
	return DocumentPreviewType.Download;
}

export function percentToStatusClass(percent: number) {
	if (percent >= 75) return 'success';
	if (percent >= 50) return 'info';
	if (percent >= 25) return 'warning';
	return 'danger';
}
