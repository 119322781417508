import { IFullName, mapToFullNameString, mapToFullNameInitials, stringHash } from '@aston/foundation';

import { UserAvatarColor, UserAvatarSize } from '../enums';
import { IUserAvatar } from '../models';

export function fakeUserAvatar(): IUserAvatar {
	return mapToUserAvatar({firstName: 'Fake', lastName: 'User'});
}

export function mapToUserAvatar(userFullName: IFullName, size: UserAvatarSize = UserAvatarSize.SM): IUserAvatar {
	let notdefined = false;
	if (!userFullName) {
		userFullName = { firstName: 'not', lastName: 'defined' };
		notdefined = true;
	}

	const id = userFullName.id || 0;
	const fullName = mapToFullNameString(userFullName);
	const initials = mapToFullNameInitials(userFullName);
	const profilePictureUrl = userFullName?.profilePictureUrl || '';
	const color = profilePictureUrl ? null : avatarColorFromString(fullName);

	return { id, fullName, initials, size, color, profilePictureUrl, notdefined };
}

export function avatarColorFromString(value: string): UserAvatarColor {
	const hash = Math.abs(stringHash(value));
	return Object.values(UserAvatarColor)[ hash % Object.keys(UserAvatarColor).length ];
}
