import { Component, OnInit, viewChild } from '@angular/core';
import { AppLanguage, DebugBarComponent, ScrolledByContainerDirective } from '@aston/foundation';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { NotificationsStoreSelectors } from 'apps/debtor-portal/src/app/root-store/notifications-store';
import { StoreLoader, UnsubscriberClass } from '@aston/foundation';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppStoreActions, AppStoreSelectors } from 'apps/debtor-portal/src/app/root-store/app-store';
import { AccountingStoreSelectors } from 'apps/debtor-portal/src/app/root-store/accounting-store';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';

import { IUserSession } from '../../../authentication-module/models';
import { environment } from '../../../../environments/environment';
import { INotificationItem } from '../../models';
import { LayoutHeaderComponent } from '../../components/layout-header/layout-header.component';


@Component({
	selector: 'master-layout',
	templateUrl: './master-layout.component.html',
	standalone: true,
	imports: [LayoutHeaderComponent, RouterOutlet, DebugBarComponent, AsyncPipe, TranslateModule, ScrolledByContainerDirective]
})
export class MasterLayoutComponent extends UnsubscriberClass implements OnInit {
	debugBar = viewChild(DebugBarComponent)
	menuCollapsed = false;

	currentLanguage$ = this.store.select(AppStoreSelectors.selectCurrentLanguage).pipe(map(l => l.language as AppLanguage));
	currentUserAvatar$ = combineLatest([
		this.store.select(AppStoreSelectors.selectCurrentUser),
		this.store.select(AppStoreSelectors.selectCurrentUserAvatar),
	]).pipe(
		filter(([user]) => !!user),
		map(([_, avatar]) => avatar),
	);

	tenant$ = this.store.select(AppStoreSelectors.selectTenant);
	currentActionId$ = this.store.select(AccountingStoreSelectors.selectDunningActionId);
	pageOverTitle$ = this.tenant$.pipe(map(tenant => tenant ? tenant.tenantName : ''));
	pageOverLogo$ = this.store.select(AppStoreSelectors.selectTenantLogo);
	userSession$ = this.store.select(AppStoreSelectors.selectCurrentUser).pipe(map(u => u as IUserSession));

	notificationsIsOpened = false;

	notificationsList$: Observable<INotificationItem[]> = this.store.select(NotificationsStoreSelectors.selectNotifications);
	notificationsAllCount$: Observable<number> = this.store.select(NotificationsStoreSelectors.selectAllCount);
	notificationsHasUnread$: Observable<boolean> = this.store.select(NotificationsStoreSelectors.selectHasUnread);
	notificationsShowUnreadOnly$: Observable<boolean> = this.store.select(NotificationsStoreSelectors.selectShowUnreadOnly);

	notificationsLoader = new StoreLoader(
		this.store.select(NotificationsStoreSelectors.selectNotificationsIsLoading),
		this.store.select(NotificationsStoreSelectors.selectNotificationsError)
	);

	// Use it to stop notifications refresh and avoid redux devtools flooding
	enableNotificationRefresh: boolean = environment.name !== 'Local';

	year = (new Date()).getFullYear();

	isAuthenticated = true;

	constructor(
		private store: Store,
	) {
		super();
	}

	ngOnInit() {
		this.store.select(NotificationsStoreSelectors.selectIsOpened)
			.pipe(takeUntil(this.destroySubscriptions$))
			.subscribe(isOpened => this.notificationsIsOpened = isOpened);
	}

	logout() {
		const logoutAction = AppStoreActions.Logout()
		this.store.dispatch(AppStoreActions.OpenConfirmationModal({textsKey: `Modal.ConfirmationDeconnexion.`}, logoutAction));
	}

	setLanguage(language: AppLanguage) {
		this.store.dispatch(AppStoreActions.ChangeLanguage({ language }));
	}
}

