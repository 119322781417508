import { mapToFullCapitalizedNameString } from '@aston/foundation';
import { mapToUserAvatar } from '@aston/user-badge';

import { INotificationItemApi, IUserItemApi } from '../models/api';
import { INotificationItem } from '../models';
import { IUserItem } from '../models';


export function mapToUserItemModel(apiModel: IUserItemApi): IUserItem {
	return {
		id: apiModel.id,
		fullName: mapToFullCapitalizedNameString(apiModel.fullName)
	};
}

export function mapToNotificationItemModel(apiModel: INotificationItemApi): INotificationItem {
	return {
		id: apiModel.id,
		sellerId: apiModel.sellerId,
		relatedEntityName: apiModel.sellerName ? apiModel.sellerName : apiModel.superDebtorName,
		category: apiModel.category,
		status: apiModel.status,
		isRead: apiModel.isRead,
		creationDate: apiModel.creationDate,
		user: mapToUserAvatar(apiModel.user),
		superDebtorId: apiModel.superDebtorId,
		realizedByUser: mapToUserAvatar(apiModel.realizedByUser)
	};
}