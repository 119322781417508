
import { AppLanguage } from '../enums';
import { IList, IListApi, IFullName, ICountryListItem, ICountryListItemTranslated, ICountriesDictionary, ICurrenciesDictionary, ICurrencyListItemTranslated, ICurrencyListItem } from '../models';

import { isPagingRequestedOnListState } from './utils.filters.function';

export function mapToListModel<T, U>(apiModel: IListApi<T>, itemsMapper: (i: T) => U): IList<U> {

    const model: IList<U> = {
        items: apiModel.items ? apiModel.items.map(i => <U> itemsMapper(i)) : []
    };

    const hasPaging = isPagingRequestedOnListState(apiModel.requestedSize || 0);
    if (hasPaging) {
        model.paging = {
            number: apiModel.number,
            requestedSize: apiModel.requestedSize,
            actualSize: apiModel.actualSize,
            totalPageCount: apiModel.totalPageCount,
            totalItemCount: apiModel.totalItemCount,
            hasPrevious: apiModel.hasPrevious,
            hasNext: apiModel.hasNext,
            previousPageNumber: apiModel.previousPageNumber,
            nextPageNumber: apiModel.nextPageNumber,
            isFirst: apiModel.isFirst,
            isLast: apiModel.isLast
        };
    }

    return model;
}

export function mapToEmailAddressString(email: string, name: string): string {
    return name?.trim() ? `${name} <${email}>` : email
}

export function mapToFullNameString(fullName: IFullName, noValueChar = ''): string {
    return (fullName ? `${fullName.firstName || ''} ${fullName.lastName || ''}`.trim() : noValueChar) || noValueChar;
}

export function createFullNameString(firstName: string, lastName: string, noValueChar = ''): string {
    return (lastName ? `${firstName || ''} ${lastName.toLocaleUpperCase()}`.trim() : noValueChar) || noValueChar;
}

export function mapToFullNameInitials(fullName: IFullName, noValueChar = ''): string {
    return fullName ? `${fullName.firstName[0]}${fullName.lastName[0]}` : noValueChar;
}

export function mapToFullCapitalizedNameString(userFullName: IFullName) {
	return [
		userFullName.firstName.slice(0, 1).toUpperCase(), userFullName.firstName.slice(1),
		' ',
		userFullName.lastName.toUpperCase()
	].join('')
}

export function mapToTranslatedCountries(countries: ICountryListItem[], lang: AppLanguage): ICountryListItemTranslated[] {
    const countriesTranslated: ICountryListItemTranslated[] = [];

    if (countries) {
        countries.forEach(c => {
            countriesTranslated.push({
                countryCode: c.countryCode,
                countryName: lang === AppLanguage.FR ? c.countryFr : c.countryEn
            });
        });

        countriesTranslated.sort((a, b) => a.countryName.localeCompare(b.countryName));
    }

    return countriesTranslated;
}

export function mapToCountriesDictionary(countries: ICountryListItemTranslated[]): ICountriesDictionary {
    const model: ICountriesDictionary = {};

    countries.forEach(country => {
        model[country.countryCode] = country.countryName;
    });

    return model;
}

export function mapToTranslatedCurrencies(currencies: ICurrencyListItem[], lang: AppLanguage): ICurrencyListItemTranslated[] {
    const currenciesTranslated: ICurrencyListItemTranslated[] = [];

    if (currencies) {
        currencies.forEach(c => {
            currenciesTranslated.push({
                currencyCode: c.iso4217Code || c.currencyCode || '',
                currencyName: lang === AppLanguage.FR ? c.currencyFr : c.currencyEn
            });
        });

        currenciesTranslated.sort((a, b) => a.currencyName.localeCompare(b.currencyName));
    }

    return currenciesTranslated;
}

export function mapToCurrenciesDictionary(currencies: ICurrencyListItemTranslated[]): ICurrenciesDictionary {
    const model: ICurrenciesDictionary = {};

    currencies.forEach(currency => {
        model[currency.currencyCode] = currency.currencyName;
    });

    return model;
}
